import React from "react"
import { Link, graphql } from "gatsby";
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import { library } from '@fortawesome/fontawesome-svg-core'
import { faLightbulb, faShoppingBasket, faUniversity, faLaptopCode } from '@fortawesome/free-solid-svg-icons'

import Layout from "../components/layout"
import SEO from "../components/seo"

import './portfolio.css';

library.add(faLightbulb, faShoppingBasket, faUniversity, faLaptopCode);

const IndexPage = ({ data }) => {
  const intl = useIntl()
  const projects = data.allMarkdownRemark.edges;
  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "portfolio-title" })}/>
      <h1 className="portfolio-header">
        <FormattedMessage id="portfolio-header" />
      </h1>
      <section>
        {projects.map(project => {
          const fm = project.node.frontmatter;
          return (
            <article className="project">
              <Link to={`/${fm.source_language}/${fm.type}/${fm.slug}`}>
                <h3>{fm.title}</h3>
              </Link>
              <p><strong><FormattedMessage id="portfolio-role" /> </strong>{fm.role}</p>
              <p><strong><FormattedMessage id="portfolio-date" /> </strong>{fm.date}</p>
              <p>{project.node.excerpt}</p>
            </article>
          )
        })}
      </section>
    </Layout>
  );
}

export default IndexPage

export const pageQuery = graphql`
  query($language: String!) {
    allMarkdownRemark(
      filter: {
        frontmatter: {
          type: {eq: "portfolio"}
          source_language:  {eq: $language}
        }
      },
      sort: {order: DESC, fields: frontmatter___weight}
    ) {
      edges {
        node {
          frontmatter {
            title
            type
            source_language
            slug
            role
            date
          }
          excerpt
        }
      }
    }
  }
`
